import React from 'react'
import { useNavigate } from '@reach/router'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setModal } from '/components/common/modal/model'
import * as tvShowsActionCreators from '/components/tvShows/tvShowsActions'
import { Carousel } from '../../common/carousel/Carousel'
import { HorizontalCard } from '../../common/cards/horizontal-card/HorizontalCard'
import { FeaturedCard } from '../../common/cards/featured-card/FeaturedCard'
import { CardTypes } from '/utils/helpers'
import { GEOBLOCK_MESSAGE } from '/utils/constants'

interface TvShowsCarouselProps {
  id: string
  title: string
  cardType: number
  carousel: { content: any[] }
}

const TvShowsCarousel = ({
  title = '',
  carousel,
  page,
  setActiveTvShow,
  getSeasons,
  setActiveSeason,
  getEpisodesBySeason,
  getEpisodes,
  setActiveEpisode,
  setIsTvShowFavorite,
  cardType,
  id,
}: TvShowsCarouselProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const maxLength = 36

  const handleTvShow = async (tvShow) => {
    if (tvShow?.blockedByAcl) {
      setModal({
        text: t(`common:message.${GEOBLOCK_MESSAGE}`, {
          item: `"${tvShow.name}"`,
        }),
      })
      return
    }

    setActiveTvShow(tvShow)

    if (tvShow.isWithSeason) {
      const seasons = await getSeasons(tvShow.id)
      const activeSeason = seasons.payload[0]
      if (activeSeason) {
        setActiveSeason(activeSeason)
        const episodes = await getEpisodesBySeason(tvShow.id, activeSeason.id)
        const activeEpisode = episodes.payload[0]
        if (activeEpisode) setActiveEpisode(activeEpisode)
      }
    } else {
      const episodes = await getEpisodes(tvShow.id)
      const activeEpisode = episodes.payload[0]
      if (activeEpisode) setActiveEpisode(activeEpisode)
    }
    navigate(`/shows/categories/0/${tvShow.id}`, { state: { page } })
  }

  const handleFavoriteClick = (tvShow) => () => {
    setIsTvShowFavorite(tvShow)
  }

  return carousel?.content?.length ? (
    <Carousel
      id={id}
      carouselTitle={title}
      showAllOption={false}
      className='featured'
    >
      {carousel.content
        .slice(0, maxLength)
        .map((item, index) =>
          cardType === CardTypes.featuredCard ? (
            <FeaturedCard
              key={`${item.tvShow.name}${item.tvShow.id}`}
              handleClick={() => handleTvShow(item.tvShow, index)}
              imageUrl={item.tvShow.landscapeImageUrl || item.tvShow.imageUrl}
              title={item.tvShow.name}
              ageRatings={item.tvShow.ageRatings}
              releaseDate={item.tvShow.year}
              favorite={item.tvShow.favorite}
              handleFavoriteClick={handleFavoriteClick(item.tvShow)}
            />
          ) : (
            <HorizontalCard
              favorite={item.tvShow.favorite}
              handleFavoriteClick={handleFavoriteClick(item.tvShow)}
              key={`${item.tvShow.name}${item.tvShow.id}`}
              handleClick={() => handleTvShow(item.tvShow, index)}
              imageUrl={item.tvShow.landscapeImageUrl || item.tvShow.imageUrl}
              title={item.tvShow.name}
            />
          )
        )}
    </Carousel>
  ) : null
}

function mapStateToProps(state) {
  return {
    carousel: state.views.tv.carousels?.find((item) => item.type === 'TV_SHOW'),
    activeTvShow: state.views.tvShows.activeTvShow,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setActiveTvShow: (tvShow) =>
      dispatch(tvShowsActionCreators.setActiveTvShow(tvShow)),
    getSeasons: (tvShowId) =>
      dispatch(tvShowsActionCreators.getSeasonsRequest(tvShowId)),
    setActiveSeason: (season) =>
      dispatch(tvShowsActionCreators.setActiveSeason(season)),
    getEpisodesBySeason: (tvShowId, seasonId) =>
      dispatch(
        tvShowsActionCreators.getEpisodesBySeasonRequest({
          tvShowId,
          seasonId,
        })
      ),
    getEpisodes: (tvShowId) =>
      dispatch(tvShowsActionCreators.getEpisodesRequest(tvShowId)),
    setActiveEpisode: (episode) =>
      dispatch(tvShowsActionCreators.setActiveEpisode(episode)),
    setIsTvShowFavorite: (tvShow) =>
      dispatch(tvShowsActionCreators.setTvShowIsFavorite(tvShow)),
  }
}

export const TvShowsCarouselContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TvShowsCarousel)
