import { createSlice, original } from '@reduxjs/toolkit'
import { Movie } from '../interfaces'
import * as vodActions from '/components/vod/store/actions'
import { assignAllCategories } from '/utils/helpers'
import {updateFavoriteVODs} from "/components/vod/store/actions";

const initialState = {
  activeMovie: null,
  activeCategory: 0,
  categories: {
    content: [],
    page: 0,
    totalPages: 0,
  },
  content: {
    search: [],
  },
  subContent: {
    search: [],
  },
  favoriteVods: [],
}

const vodSlice = createSlice({
  name: 'vod',
  initialState,
  reducers: {
    setActiveMovie: (state, { payload }) => {
      state.activeMovie = payload
    },
    setActiveMovieUrl: (state, { payload }) => {
      if (state.activeMovie) {
        state.activeMovie = Object.assign(state.activeMovie, { url: payload })
      }
    },
    setSearchContent: (state, { payload }) => {
      state.content.search = payload
    },
    setCategoriesPageState: (state, { payload }) => {
      const { page } = payload
      state.categories.page = page
    },
  },
  extraReducers: {
    [vodActions.getVODUrl.fulfilled.toString()]: (state, { payload }) => {
      const { drm, playbackUrl, stoppedTime } = payload
      const activeMovie = original(state.activeMovie)
      if (activeMovie) {
        state.activeMovie = Object.assign(state.activeMovie, {
          url: playbackUrl,
          drm,
          stoppedTime,
        })
      }
    },
    [vodActions.getVODUrl.rejected.toString()]: (state) => {
      const activeMovie = original(state.activeMovie)
      if (activeMovie) {
        state.activeMovie = Object.assign(state.activeMovie, {
          url: null,
          drm: null,
          stoppedTime: null,
        })
      }
    },

    [vodActions.getVodsByCategory.fulfilled.toString()]: (
      state,
      { payload }
    ) => {
      const { content, last, number, totalElements, totalPages } =
        payload.data.payload
      state.content[payload.categoryId] = {
        movies: content,
        props: {
          last,
          curNumber: number,
          totalElements,
          totalPages,
        },
      }
    },

    [vodActions.updateVodsByCategory.fulfilled.toString()]: (
      state,
      { payload }
    ) => {
      const content = state.content[payload.categoryId]
      if (content) {
        content.movies = content.movies.concat(payload.data.payload.content)
        content.props = {
          last: payload.data.payload.last,
          curNumber: payload.data.payload.number,
          totalElements: payload.data.payload.totalElements,
          totalPages: payload.data.payload.totalPages,
        }
      }
    },

    [vodActions.getVodsBySubCategory.fulfilled.toString()]: (
      state,
      { payload }
    ) => {
      state.subContent[payload.categoryId] = {
        movies: payload.content,
        props: {
          last: payload.last,
          curNumber: payload.number,
          totalElements: payload.totalElements,
          totalPages: payload.totalPages,
        },
      }
    },

    [vodActions.updateVodsBySubCategory.fulfilled.toString()]: (
      state,
      { payload }
    ) => {
      const content = state.subContent[payload.categoryId]
      if (content) {
        content.movies = content.movies.concat(payload.content)
        content.props = {
          last: payload.last,
          curNumber: payload.number,
          totalElements: payload.totalElements,
          totalPages: payload.totalPages,
        }
      }
    },

    [vodActions.getCategories.fulfilled.toString()]: (state, { payload }) => {
      state.categories.content = state.categories.content.length
        ? state.categories.content.concat(payload.content)
        : assignAllCategories(0, { data: payload.content })
      state.categories.totalPages = payload.totalPages
      state.categories.page = payload.number
    },

    [vodActions.getSubCategories.fulfilled.toString()]: (
      state,
      { payload }
    ) => {
      const { content, number, totalPages } = payload.data.payload
      state.subContent[payload.categoryId] = {
        subs: content,
        pagination: {
          page: number,
          totalPages,
        },
      }
    },

    [vodActions.getSubCategoriesAndVods.fulfilled.toString()]: (
      state,
      { payload }
    ) => {
      const { content, number, totalPages } = payload.data.payload
      const subContent = state.subContent[payload.categoryId]

      if (subContent) {
        subContent.subs = subContent.subs.concat(content)
        subContent.pagination.page = number
        subContent.pagination.totalPages = totalPages
      } else {
        state.subContent[payload.categoryId] = {
          subs: content,
          pagination: {
            page: number,
            totalPages,
          },
        }
      }
    },

    [vodActions.updateSubCategories.fulfilled.toString()]: (
      state,
      { payload }
    ) => {
      const { content, number, totalPages } = payload.data.payload
      const subContent = state.subContent[payload.categoryId]
      if (subContent) {
        subContent.subs = subContent.subs.concat(content)
        subContent.pagination.page = number
        subContent.pagination.totalPages = totalPages
      }
    },

    [vodActions.getVodsBySearch.fulfilled.toString()]: (state, { payload }) => {
      state.content.search = payload.content
    },
    [vodActions.setIsVodFavorite.fulfilled.toString()]: (
      state,
      { payload }: { payload: Movie }
    ) => {
      Object.keys(state.content).forEach((categoryId) => {
        if (categoryId === '0') {
          const movieIndex = state.content[0].movies.findIndex(
            (movie) => movie.id === payload.id
          )
          state.content[0].movies.splice(movieIndex, 1, payload)
        }
        if (categoryId !== 'search') {
          const movieIndex = state.content[categoryId].movies.findIndex(
            (movie) => movie.id === payload.id
          )
          movieIndex > -1 &&
            state.content[categoryId].movies.splice(movieIndex, 1, payload)
        }
        if (categoryId === 'search') {
          const movieIndex = state.content[categoryId].findIndex(
            (movie: Movie) => movie.id === payload.id
          )
          movieIndex > -1 &&
            state.content[categoryId].splice(movieIndex, 1, payload)
        }
      })
    },
    [vodActions.getOnlyFavoriteVods.fulfilled.toString()]: (
      state,
      { payload }
    ) => {
      state.favoriteVods = payload.content
    },
    [vodActions.getOnlyFavoriteVods.rejected.toString()]: (state) => {
      state.favoriteVods = initialState.favoriteVods
    },
    [vodActions.updateFavoriteVODs.toString()]: (state, { payload }) => {
      state.favoriteVods.every((vod: Movie) => {
        const result = vod.id === payload.id
        if (result) {
          vod.favorite = payload.favorite
        }
        return result;
      })
    }
  },
})

export const actions = {
  ...vodSlice.actions,
  ...vodActions,
}

export const reducer = vodSlice.reducer
