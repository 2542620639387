import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as service from '/services/api/v3/tvShows'
import {
  CHANGE_TVSHOWS_SEARCH_QUERY,
  GET_TVSHOWS_SEARCH_REQUEST,
  SET_ACTIVE_TVSHOW,
  GET_SEASONS_REQUEST,
  SET_SEASONS,
  SET_ACTIVE_SEASON,
  GET_EPISODES_REQUEST,
  SET_EPISODES,
  SET_ACTIVE_EPISODE,
  GET_EPISODE_URL_REQUEST,
  GET_TVSHOWS_CATEGORIES_REQUEST,
  GET_TVSHOWS_CATEGORY_REQUEST,
} from '../../root/actionTypes'
import {
  showPreloader,
  hidePreloader,
  hidePreloaderImmediately,
} from '/models/preloader'
import type { TvShow, TvShowSeason, TvShowEpisode } from './interfaces'
import { updateFeaturedShowIsFavorite } from '../tv/store/actions'
import { sortBySortOrder } from '~/utils/helpers'
import {Movie} from "/components/vod/interfaces";
import {updateFavoriteVODs} from "/components/vod/store/actions";

// new actions
export const updateUrl = createAction<string>('UPDATE_URL')

export const getTvShowByIdRequest = createAsyncThunk(
  'GET_TVSHOW_BY_ID',
  async (tvShowId: number, { rejectWithValue }) => {
    try {
      const tvShow = await service
        .findTvShowById(tvShowId)
        .then((response) => response?.payload[0])
      if (tvShow.isTrailerExists) {
        const trailerUrl = await service
          .findTvShowsTrailerUrl(tvShowId)
          .then((response) => response?.payload?.playbackUrl)

        tvShow.trailerUrl = trailerUrl || ''
      }
      return tvShow
    } catch (error) {
      return rejectWithValue([])
    }
  }
)

export const changeTvShowsSearch = createAction<[]>('CHANGE_TVSHOWS_SEARCH')
export const changeTvShowsSearchQuery = createAction<string>(
  CHANGE_TVSHOWS_SEARCH_QUERY
)
export const getTvShowsBySearchRequest = createAsyncThunk(
  GET_TVSHOWS_SEARCH_REQUEST,
  async (
    { searchQuery, categoryId }: { searchQuery: string; categoryId: number },
    { rejectWithValue, dispatch }
  ) => {
    // showPreloader()
    dispatch(changeTvShowsSearchQuery(searchQuery))
    try {
      const result = await service.findTvShowsBySearch({
        searchQuery,
        categoryId,
      })
      hidePreloader()
      return result
    } catch (error) {
      console.error(error)
      hidePreloader()
      return rejectWithValue([])
    }
  }
)

export const setActiveTvShow = createAction<TvShow | Record<string, never>>(
  SET_ACTIVE_TVSHOW
)

export const getSeasonsRequest = createAsyncThunk(
  GET_SEASONS_REQUEST,
  async (tvShowId: number, { rejectWithValue }) => {
    try {
      // showPreloader()
      return await service.findSeasons(tvShowId).then(hidePreloader)
    } catch (error) {
      hidePreloader()
      console.error(error)
      return rejectWithValue([])
    }
  }
)

export const setSeasons = createAction<TvShowSeason[] | []>(SET_SEASONS)

export const setActiveSeason = createAction<
  TvShowSeason | Record<string, never>
>(SET_ACTIVE_SEASON)

export const getEpisodesRequest = createAsyncThunk(
  GET_EPISODES_REQUEST,
  async (tvShowId: number, { rejectWithValue }) => {
    try {
      // showPreloader()
      const episodes = await service.findEpisodes(tvShowId).then(hidePreloader)
      return sortBySortOrder(episodes)
    } catch (error) {
      console.error(error)
      hidePreloader()
      return rejectWithValue([])
    }
  }
)

export const getEpisodesBySeasonRequest = createAsyncThunk(
  GET_EPISODES_REQUEST,
  async (
    { tvShowId, seasonId }: { tvShowId: number; seasonId: number },
    { rejectWithValue }
  ) => {
    try {
      const episodes = await service
        .findEpisodesBySeason(tvShowId, seasonId)
        .then(hidePreloader)
      return sortBySortOrder(episodes)
    } catch (error) {
      hidePreloader()
      console.error(error)
      return rejectWithValue([])
    }
  }
)

export const setEpisodes = createAction<TvShowEpisode[] | []>(SET_EPISODES)

export const setActiveEpisode = createAction<
  TvShowEpisode | Record<string, never>
>(SET_ACTIVE_EPISODE)

export const getEpisodeUrlBySeasonRequest = createAsyncThunk(
  GET_EPISODE_URL_REQUEST,
  async (
    {
      tvShowId,
      seasonId,
      episodeId,
    }: { tvShowId: number; seasonId: number; episodeId: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await service.findEpisodeUrlBySeason(
        tvShowId,
        seasonId,
        episodeId
      )
      return response.payload
    } catch (error) {
      console.error(error)
      return rejectWithValue([])
    }
  }
)

export const getEpisodeUrlSuccess = createAction<string>('UPDATE_EPISODE_URL')
export const getEpisodeUrlRequest = createAsyncThunk(
  GET_EPISODE_URL_REQUEST,
  async (
    { tvShowId, episodeId }: { tvShowId: number; episodeId: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await service.findEpisodeUrl(tvShowId, episodeId)
      return response.payload
    } catch (error) {
      console.error(error)
      return rejectWithValue(error)
    }
  }
)

export const getActiveCategoryRequest = createAction<number>(
  GET_TVSHOWS_CATEGORY_REQUEST
)

export const getTvShowsByCategory = createAsyncThunk(
  'GET_TVSHOWS_BY_CATEGORY_REQUEST',
  async (
    { id, page, count }: { id: number; page: number; count: number },
    { rejectWithValue, dispatch }
  ) => {
    // showPreloader()
    let content = []
    try {
      const response = await service.findTvShowsByCategoryByPage({
        id,
        page,
        count,
      })

      content = response.payload
      // hidePreloader()

      return { activeCategory: id, content }
    } catch (error) {
      // hidePreloader()
      return rejectWithValue({ activeCategory: id })
    }
  }
)

export const getCategoriesRequest = createAsyncThunk(
  GET_TVSHOWS_CATEGORIES_REQUEST,
  async (categories: [], { rejectWithValue, dispatch }) => {
    try {
      const response = await service.findCategoriesPageByPage()
      return response
    } catch (error) {
      return rejectWithValue([])
    }
  }
)

export const getTvShowsByCategoryByPage = createAsyncThunk(
  'GET_TVSHOWS_BY_CATEGORY_BY_PAGE',
  async (
    { id, page, count }: { id: number; page: number; count: number },
    { rejectWithValue }
  ) => {
    // showPreloader()
    let content = []
    try {
      showPreloader()
      const response = await service.findTvShowsByCategoryByPage({
        id,
        page,
        count,
      })
      content = response.payload
      hidePreloaderImmediately()
      return { activeCategory: id, content }
    } catch (error) {
      hidePreloaderImmediately()
      return rejectWithValue([])
    }
  }
)

export const resetHistory = createAction('RESET_TV_SHOW_HISTORY')
export const updateHistory = createAction<{ [key: number]: number }>(
  'UPDATE_TV_SHOW_HISTORY'
)

export const getLastAddedTvShows = createAsyncThunk(
  'tvShows/lastAdded',
  async ({ count }: { count: number }, { rejectWithValue }) => {
    try {
      const response = await service.fetchLastAddedTvShows(count)
      return response.payload
    } catch (error) {
      return rejectWithValue([])
    }
  }
)
export const getOnlyFavoriteTvShows = createAsyncThunk(
  'tvShow/getOnlyFavorite',
  async (
    { page = 0, count = 36 }: { page?: number; count?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await service.fetchFavoriteTvShows(page, count)
      return response.payload
    } catch (error) {
      rejectWithValue([])
    }
  }
)

export const updateFavoriteTvShows = createAction<TvShow>('tvShow/updateFavoriteTvShows')

export const setTvShowIsFavorite = createAsyncThunk(
  'tvShow/updateIsTvShowFavorite',
  async (tvShow: TvShow, { rejectWithValue, dispatch }) => {
    try {
      const response = await service.markTvShowAsFavorite(
        tvShow.id,
        !tvShow.favorite
      )

      dispatch(updateFeaturedShowIsFavorite({ ...tvShow, favorite: !tvShow.favorite }))
      dispatch(updateFavoriteTvShows({ ...tvShow, favorite: !tvShow.favorite } as any))
      dispatch(getOnlyFavoriteTvShows({ page: 0, count: 36 }))
      return { ...tvShow, favorite: !tvShow.favorite }
    } catch (error) {
      rejectWithValue([])
    }
  }
)
