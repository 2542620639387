import {createAction, createAsyncThunk} from '@reduxjs/toolkit'
import { fetchVODByCategory } from '~/services/api/vod'
import {
  fetchCategories,
  fetchFavoriteVods,
  fetchSubCategories,
  fetchVods,
  fetchVodsByIds,
  fetchVODUrl,
  getVodsTrailerUrl,
  markVodAsFavorite,
} from '~/services/api/v3/vods'
import { actions as vodActions } from '~/components/vod/store'
import { showPreloader, hidePreloaderImmediately } from '/models/preloader'
import { Movie } from '../interfaces'
import { updateFeaturedVodIsFavorite } from '/components/tv/store/actions';

export const getVODById = createAsyncThunk(
  'vod/getVODRequest',
  async (vodId: number, { rejectWithValue, dispatch }) => {
    try {
      const { payload } = await fetchVodsByIds(String(vodId))

      if (payload.length) {
        const vod = payload[0] as Movie
        const trailerUrl = vod?.trailerPresent
          ? await getVodsTrailerUrl(vodId).then(
              (response) => response?.payload?.playbackUrl
            )
          : ''

        dispatch(vodActions.setActiveMovie({ ...vod, trailerUrl: trailerUrl }))
        dispatch(vodActions.getVODUrl(vodId))
        return { ...vod }
      } else {
        return rejectWithValue({})
      }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getVODUrl = createAsyncThunk(
  'vod/getVODUrlRequest',
  async (vodId: number, { rejectWithValue }) => {
    try {
      const result = await fetchVODUrl(vodId)
      return result.payload
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getVodsByCategory = createAsyncThunk(
  'vod/getVodCategoryRequest',
  async (
    {
      categoryId,
      page,
      count,
    }: { categoryId: number; page?: number; count?: number },
    { rejectWithValue }
  ) => {
    try {
      showPreloader()
      const result = await fetchVods(page, count, categoryId)
      hidePreloaderImmediately()
      if (result.payload && !result.payload.content.length) {
        return rejectWithValue({})
      }
      return { categoryId, data: result }
    } catch (e) {
      hidePreloaderImmediately()
      return rejectWithValue(e)
    }
  }
)

export const getOnlyFavoriteVods = createAsyncThunk(
  'vod/onlyFavorite',
  async (
    { page = 0, count = 36 }: { page?: number; count?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetchFavoriteVods(page, count)
      return response.payload
    } catch (error) {
      rejectWithValue([])
    }
  }
)

export const updateVodsByCategory = createAsyncThunk(
  'vod/updateVodCategoryRequest',
  async (
    {
      categoryId,
      page,
      count,
    }: { categoryId: number; page?: number; count?: number },
    { rejectWithValue }
  ) => {
    try {
      showPreloader()
      const result = await fetchVods(page, count, categoryId)
      hidePreloaderImmediately()
      return { categoryId, data: result }
    } catch (e) {
      hidePreloaderImmediately()
      return rejectWithValue(e)
    }
  }
)

export const getVodsBySubCategory = createAsyncThunk(
  'vod/getVodSubCategoryRequest',
  async (
    {
      categoryId,
      page,
      count,
    }: { categoryId: number; page?: number; count?: number },
    { rejectWithValue }
  ) => {
    try {
      const result = await fetchVODByCategory(categoryId, page, count)

      return { categoryId, data: result }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const updateVodsBySubCategory = createAsyncThunk(
  'vod/updateVodSubCategoryRequest',
  async (
    {
      categoryId,
      page,
      count,
    }: { categoryId: number; page?: number; count?: number },
    { rejectWithValue }
  ) => {
    try {
      const result = await fetchVODByCategory(categoryId, page, count)

      return { categoryId, data: result }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getVodsBySearch = createAsyncThunk(
  'vod/getVodsBySearchRequest',
  async (
    { q, page = 0, count = 999 }: { q: string; page?: number; count?: number },
    { rejectWithValue }
  ) => {
    try {
      const result = await fetchVods(page, count, 0, null, q)
      return q ? result.payload : [] // if submit empty field
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

/* actions with v3 */
/* - */
export const getCategories = createAsyncThunk(
  'vod/getCategoriesRequest',
  async (
    { page, count = 10 }: { page: number; count?: number },
    { rejectWithValue }
  ) => {
    try {
      const result = await fetchCategories(page, count)
      return result.payload
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

/* get sub categories by category id */
export const getSubCategories = createAsyncThunk(
  'vod/getSubCategoriesRequest',
  async (
    {
      categoryId,
      page,
      count = 10,
    }: { categoryId: number; page: number; count: number },
    { rejectWithValue }
  ) => {
    try {
      const result = await fetchSubCategories(categoryId, page, count)

      return { categoryId, data: result }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getSubCategoriesAndVods = createAsyncThunk(
  'vod/getSubCategoriesAndVodsRequest',
  async (
    {
      categoryId,
      page,
      count = 10,
    }: { categoryId: number; page: number; count: number },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const result = await fetchSubCategories(categoryId, page, count)
      const ids = result.payload.content.map((item) => item.id)

      await Promise.all(
        ids.map((id) =>
          dispatch(
            vodActions.getVodsByCategory({ categoryId: id, page: 0, count: 36 })
          )
        )
      )

      return { categoryId, data: result }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const updateSubCategories = createAsyncThunk(
  'vod/updateSubCategoriesRequest',
  async (
    {
      categoryId,
      page,
      count = 10,
    }: { categoryId: number; page: number; count?: number },
    { rejectWithValue }
  ) => {
    try {
      const result = await fetchSubCategories(categoryId, page, count)

      return { categoryId, data: result }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const setIsVodFavorite = createAsyncThunk(
  'vods/updateIsVodFavorite',
  async (movie: Movie, { rejectWithValue, dispatch }) => {
    try {
      const response = await markVodAsFavorite(movie.id, !movie.favorite)
      dispatch(
          vodActions.updateFavoriteVODs({
          ...movie,
          favorite: !movie.favorite,
        })
      )
      dispatch(updateFeaturedVodIsFavorite({
        ...movie,
        favorite: !movie.favorite,
      }))
      dispatch(vodActions.getOnlyFavoriteVods({ page: 0, count: 36 }))
      return { ...movie, favorite: !movie.favorite }
    } catch (error) {
      rejectWithValue([])
    }
  }
)

export const updateFavoriteVODs = createAction<Movie>('vods/updateFavoriteVODs')
/* - */
/* END */
