import React, { useRef, useState } from 'react';
import { useStore } from 'effector-react'
import {
  doConfirm,
  resendCodeFx,
  $registerStatus,
  $seconds,
  $counterInOperation,
  $registrationError,
  resetError,
  $permanentDisabling, $register,
} from '/models/registration';
import { Logo } from '/components/login/components/forms/FormLogo'
import { Button } from '/components/common/button'
import { Counter } from '../counter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons'

import s from '../../login.module.scss'

export const VerifyAccount = ({ id }: { id: string }): JSX.Element => {
  const [code, setCode] = useState('')
  const codeRef = useRef<HTMLInputElement>(null)
  const registerStatus = useStore($registerStatus)
  const seconds = useStore($seconds)
  const counterInProcess = useStore($counterInOperation)
  const errorCode = useStore($registrationError)?.code
  const permanentDisabling = useStore($permanentDisabling)
  const register = useStore($register)

  const handleCodeInput = (e) => {
    setCode(e.target?.value)
    resetError()
  }

  const submit = (e) => {
    e.preventDefault()
    const code = codeRef?.current?.value

    if (id && code) {
      doConfirm({ id, code })
    }
  }

  const getNewCode = () => {
    if (id) {
      resendCodeFx({ id })
    }
  }

  return (
    <div className={s.loginBox}>
      <div className={s.loginHeader}>
        <Logo />
        <h1 className={s.loginTitle}>Verify account</h1>
        <h2 className={`${s.message} ${s.messageLarge}`}>
          We have sent a code to your email. Please enter the code below
        </h2>
      </div>
      <form
        className={`${s.loginForm} ${s.loginForm__paddingBottom}`}
        onSubmit={submit}
        noValidate
      >
        <div className={s.loginForm__field}>
          <FontAwesomeIcon className={s.loginForm__icon} icon={faLockAlt} />
          <div className={s.loginForm__label}>
            <input
              ref={codeRef}
              id='code'
              type='text'
              className={`${s.loginForm__input}`}
              placeholder={' '}
              onInput={handleCodeInput}
            />
            <label htmlFor='code'>Code</label>
          </div>
        </div>
        {register ? <p className={`${s.message} ${s.secondary}`}>The code will expire in {register?.timeValue} {register?.timePeriod}.</p> : ''}
        <Button
          type='submit'
          title='Submit'
          isDisabled={
            !code ||
            errorCode === 'WG0063' ||
            errorCode === 'WG0064' ||
            !!registerStatus
          }
          loading={!!registerStatus}
          className={`${
            s.button
          } button-primary button-small button-full-width ${
            registerStatus ? s.buttonDisabled : ''
          } `}
        />
        <p className={`${s.message} ${s.messageMarginBot}`}>
          Note! If you can not find the email with code reload your mail or
          check the spam
        </p>
        <Button
          type='button'
          title='New Code'
          isDisabled={permanentDisabling || counterInProcess}
          className={`${s.button} button-ghost button-small button-full-width`}
          handleClick={getNewCode}
        />
        {counterInProcess && <Counter time={seconds} />}
      </form>
    </div>
  )
}
